import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
  setup: function setup() {
    var bannerList = ref([{
      url: require("../../assets/banner.jpg")
    } // {
    //   url: require("../../assets/banner2.jpg"),
    // },
    ]);

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    onMounted(function () {
      handlebackTop();
    });
    return {
      bannerList: bannerList,
      handlebackTop: handlebackTop
    };
  }
});